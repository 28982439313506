import { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import classes from './Loading.module.scss';

type LoadingContainerProps = {
  fontSize?: number;
};

const Loading: FC<LoadingContainerProps> = ({ fontSize = 32 }) => {
  return (
    <div className={classes.loadingContainer}>
      <LoadingOutlined style={{ fontSize }}/>
    </div>
  );
};

export default Loading;
